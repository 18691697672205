import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  name: 'products',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  data() {
    return {
      fullName: '',
      email: '',
      phoneNumber: '',
      message: '',
      category: 'CORPORATE',
      isError: ''
    }
  },
  computed: {
    ...mapGetters('common', ['adminGeneral', 'isMobile'])
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  methods: {
    ...mapActions('common', ['sendWhatsApp', 'showLoading', 'hideLoading']),
    ...mapActions('contact', ['contactUsWithCategory']),
    toPage(number) {
      switch (number) {
        case 0:
          this.sendWhatsApp({ number: this.adminGeneral })
          break
        case 1:
          this.$router.push('/products/lms')
          break
        case 2:
          this.$router.push('/products/talent')
          break
      }
    },
    sendMessage() {
      // eslint-disable-next-line no-useless-escape
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      const pattern = /^[0-9]{10,13}$/
      this.isError = ''
      if (!this.isValidForm()) {
        this.isError = 'There are empty fields! Please fill in all information.'
        return
      }

      if (!regex.test(this.email)) {
        this.isError = 'Your email format is invalid!'
        return
      }

      if (!pattern.test(this.phoneNumber)) {
        this.isError = 'Your phone number format is invalid!'
        return
      }

      this.showLoading()
      this.contactUsWithCategory({
        payloads: {
          email: this.email,
          fullName: this.fullName,
          message: this.message,
          phoneNumber: this.phoneNumber,
          category: this.category
        }
      }).then(
        () => {
          this.isError = ''
          this.fullName = ''
          this.email = ''
          this.phoneNumber = ''
          this.message = ''
          showVueToast('Message is sent!', 'success', 3000)
          this.hideLoading()
        },
        () => (this.isError = 'An error occurred on the server. Please try again!')
      )
    },
    isValidForm() {
      return this.fullName !== '' && this.phoneNumber !== '' && this.email !== '' && this.message !== '' && this.category !== ''
    },
    toPartner() {
      this.$router.push('/register-alliance')
    }
  }
}
